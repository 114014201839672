.pagination_upper_con {
  display: flex;
  gap: 20px;
}
.select_con {
  display: flex;
  height: fit-content;
  align-items: center;
}
.pagination_con .pagination_item button svg {
  font-size: 30px !important;
}
.pagination_con {
  flex-direction: column;
  align-items: center;
}
.pagination_inner {
  display: flex;
  border-radius: 9px;
}
.pagination_inner .pagination_item:nth-child(1) button {
  border-radius: 7px 0 0 7px !important;
}
.pagination_inner .pagination_item:nth-child(2) button {
  border-radius: revert !important;
  border-right: none !important;
  border-left: none !important;
}
.pagination_inner .pagination_item:nth-child(3) button {
  border-radius: 0 7px 7px 0 !important;
}
.pagination_inner .pagination_item button {
  border: 1px solid #7bebff !important;
  box-shadow: 0 1px 3px -2px #9098a9;
  background-color: #f9f9f9 !important;
}
.pagination_inner .pagination_item button:disabled {
  color: #dddddd !important;
}
.pagination_inner .pagination_item button:hover {
  transform: translateY(0) !important;
}