::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}
body {
  min-height: 100vh;
}
.main_page_div {
  min-height: 100vh;
  background: #dbebf5;
}
.main_page_div > div > div:has(.signin_con) {
  background-color: #f9f9f9;
}

/* navbar */
.navbar_con {
  background: linear-gradient(
    0deg,
    rgb(219 235 245) 0%,
    rgba(29, 243, 253, 0.5) 50%,
    rgba(69, 124, 252, 0.5) 100%
  ) !important;
}
nav ol li:nth-child(even) {
  font-size: 1rem !important;
}
.header_bg {
  background: linear-gradient(
    0deg,
    rgb(219 235 245) 0%,
    rgba(29, 243, 253, 0.5) 50%,
    rgba(69, 124, 252, 0.5) 100%
  ) !important;
}
header {
  position: inherit !important;
  min-height: auto !important;
}

/* table */
td {
  padding: 0.3rem 0.5rem !important;
}
th {
  position: sticky;
  top: 0;
  background-color: #b2edf7 !important;
}
table {
  border-collapse: collapse;
}
.table-responsive {
  overflow-x: initial !important;
}
.inward_modal .table-responsive {
  scrollbar-width: thin !important;
}
.inward_modal .new_table {
  margin-bottom: 0;
}

.inward_modal .table-responsive::-webkit-scrollbar {
  height: 6px !important;
  cursor: pointer;
}
.con .new_table {
  /* table-layout: fixed; */
  /* white-space: nowrap; */
  margin-bottom: 0;
}
.con .new_table td::-webkit-scrollbar,
.con .new_table th::-webkit-scrollbar {
  height: 5px;
}
.con td:nth-child(1),
.con th:nth-child(1) {
  padding-left: 25px !important;
}
.con .card-header {
  background-color: #fff !important;
}
.card-header > div {
  padding-top: 12px;
  padding-bottom: 12px;
}
.con .card-footer,
.return_entry_modal .card-footer {
  padding: 0.8rem 1.1rem;
  display: flex;
  justify-content: space-between;
  background: #fff;
  border-radius: 0 0 15px 15px !important;
}
.inward_con .card-footer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.heading_input_con {
  height: fit-content;
  gap: 20px;
  display: grid;
  grid-template-columns: 2.5fr 1fr;
}
.minQty_con .heading_input_con {
  grid-template-columns: 1fr;
}
.heading_input_con .heading_input_child:first-child {
  background-color: #f9f9f9 !important;
  border-right: 2px solid #11cdef !important;
  border-bottom: 2px solid #11cdef !important;
}
.heading_searchbar {
  border-color: #7bebff !important;
}
.inward_card_body {
  height: calc(100vh - 290px);
  overflow: scroll;
}
.inward_card_body .table_header_con th:last-child {
  display: none;
}
.table_card_body {
  height: calc(100vh - 272px);
  overflow: scroll;
  overflow-x: auto;
  padding: 0 !important;
}
.warehouse_inventory_edit_modal_table .table_card_body {
  height: auto;
  max-height: 50vh;
}
.table_card_body::-webkit-scrollbar {
  height: 8px;
  cursor: pointer;
}
.importProduct_con .card-body {
  padding: 0 !important;
}
.seller_address_cell {
  min-width: 200px;
}
.importProduct_con .card-footer,
.importFile_con .card-footer {
  justify-content: flex-end;
}
.card-header {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}
.thead-light {
  background: #b2edf7;
  z-index: 2;
  position: relative;
}
.productSeller_con .table_header_con th:last-child {
  text-align: center;
}
.table_head_text {
  white-space: nowrap;
}
.warehouse_stock_filter {
  filter: drop-shadow(0px 0px 2px black);
}
.heading_title_con {
  display: flex;
  align-items: center;
  gap: 50px;
}
.warehouse_add_con .new_table tr td:last-child {
  min-width: 120px;
}

/* button  */
.con .New_btn_2,
.return_entry_modal .New_btn_2 {
  padding: 4px;
  border-radius: 50% !important;
  min-width: auto;
}
.con .page-item.disabled .page-link,
.return_entry_modal .page-item.disabled .page-link {
  padding: 5px 8px;
}
.con .New_btn_3,
.return_entry_modal .New_btn_3 {
  padding: 6px 14px;
  border-radius: 50% !important;
  border: none !important;
  min-width: auto;
}
.con .edit_btn:active {
  border: none !important;
  outline: none !important;
}
.page-link {
  border: none;
}
.usertable_buttons button {
  cursor: pointer;
}
.usertable_buttons {
  width: 130px;
  min-width: 130px;
  text-align: center;
}
.usertable_buttons > * {
  display: flex;
}
.table_header_con th:last-child {
  width: 130px;
  text-align: center;
}
.warehouse_add_con .table_header_con th:last-child,
.warehouse_stock_con .table_header_con th:last-child,
.admin_addOrder_con .table_header_con th:last-child,
.adminOrder_con .table_header_con th:last-child,
.order_Seller_con .table_header_con th:last-child,
.inward_list_con .table_header_con th:last-child,
.minQty_con .table_header_con th:last-child {
  width: auto;
  text-align: start;
}
.product_button_con,
.product_excel_buttons {
  display: flex;
  gap: 20px;
}
.product_button_con {
  margin-inline: 30px;
}
.card-header .download_file_btn svg {
  font-size: 1.5rem !important;
}
.warehouse_stock_con .heading_input_con,
.warehouse_add_con .heading_input_con {
  grid-template-columns: 3.2fr 1.5fr 1.5fr;
}
.inward_list_con .heading_input_con,
.order_Seller_con .heading_input_con,
.adminOrder_con .heading_input_con {
  grid-template-columns: 1fr;
}
.warehouse_return_btn svg {
  transform: rotateY(180deg);
}
.admin_addOrder_btn_con {
  display: flex;
  gap: 30px;
  padding-left: 25px !important;
  padding-right: 20px !important;
}
.admin_addOrder_btn_con .admin_addOrder_btn button,
.inward_verify_btn button {
  background: transparent;
  border: none;
}
.admin_addOrder_btn_con .admin_addOrder_status_btn button {
  padding: 0;
}
.warehouse_inventory_edit_damage_btn {
  display: flex;
  text-align: end;
  justify-content: flex-end;
  align-items: center;
  width: fit-content !important;
}
.btn.edit_btn:disabled {
  border: none;
  opacity: 0.3;
}

/* modal  */
.modal-content {
  box-shadow: 0 0 0 10000px rgba(0, 0, 0, 0.3);
}
.logout_btn {
  margin-left: 15px !important;
}
.modal-backdrop.show {
  --bs-backdrop-opacity: 0 !important;
  opacity: 0 !important;
}
.delete_modal .modal-content {
  box-shadow: 0 0 0 10000px rgba(0, 0, 0, 0.1);
}
.inwardModal_header .modal-title {
  display: flex;
  gap: 30px;
  align-items: center;
}
.inwardModal_header.modal-header .modal-title {
  gap: 20px;
}
.inward_modal_input_td > * {
  border-radius: revert !important;
  padding: 0.2rem 0.1rem !important;
  border-color: #999999 !important;
  margin-top: 2px !important;
}
.inward_modal_table {
  max-height: 30vh;
}
.inward_verify_modal .heading_input_child {
  width: auto !important;
}
.inward_verify_modal .inward_modal_pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inward_verify_modal .card-footer .pagination_inner button {
  min-width: 45px;
  padding: 0;
}
.inward_modal_card_body {
  padding: 0 !important;
}
.inward_modal_card_body {
  padding: 0 !important;
}
.inward_modal_card_body .modal-body {
  padding: 0 !important;
  height: calc(100vh - 300px);
  overflow: scroll;
  overflow-x: auto;
}
.inward_modal_card_body .modal-body::-webkit-scrollbar {
  height: 8px;
}
.inwardModal_header {
  position: relative;
}

.inward_modal_export_btn {
  position: absolute;
  right: 80px;
}
.inward_modal_card_body th {
  position: sticky;
  top: 0;
}
.inward_modal_card_body .table_header_con th:nth-last-child(2) {
  text-align: center;
}
.inward_modal_verify_btn_td {
  display: flex;
  align-items: center;
  gap: 20px;
}
.inward_modal_verify_btn_td .inward_modal_verify_details {
  display: flex;
  gap: 15px;
}
.inward_modal_verify_btn:disabled {
  padding-inline: 29px;
  background-color: transparent !important;
  box-shadow: none !important;
}
.inward_modal_verify_btn:disabled svg {
  font-size: 1.4rem !important;
}
.inward_modal_verifiedDate {
  min-width: 100px;
}
.inward_modal_pagination > div > span {
  display: none;
}
.inward_modal_footer_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.inward_modal_footer_details_inner {
  display: flex;
  gap: 20px;
}
.warehouse_add_modal .userTable_header .modal-title {
  display: flex;
  gap: 50px;
  align-items: center;
}
.inward_verify_btn {
  max-width: 120px;
}
.inward_modal_card_body .inward_verify_btn {
  max-width: inherit;
}
.inward-error-edit-row {
  background-color: #ff000057;
}
.inward-modal-error-row {
  background-color: #ff000057;
}
.inward_modal_clear_icon {
  cursor: pointer;
  transform: rotate(270deg);
}
.warehouse_add_modal .pagination_select {
  font-weight: 400;
  color: #555;
  font-size: 0.875rem;
  line-height: 1.4;
  padding: 0.85rem;
  margin-left: 0;
  width: 200px;
}
.adminOrder_modal_inputs {
  display: flex;
  gap: 20px;
}
.warehouse_add_modal .warehouse_date .col-md-6 {
  width: 200px;
}
.warehouse_add_modal .warehouse_date .col-md-6 .form-control {
  border: 1px solid #7bebff;
  border-right: 2px solid #11cdef !important;
  border-bottom: 2px solid #11cdef !important;
  border-radius: 5px;
  background: #f9f9f9 !important;
  box-shadow: 0 1px 3px -2px #9098a9;
}
.warehouse_add_modal .warehouse_date .col-md-6 .form-control:focus,
.warehouse_add_modal .warehouse_date .col-md-6 .form-control:active {
  outline: none;
  border-color: #11cdef;
  box-shadow: 0 0 0 2px rgba(0, 119, 255, 0.2);
}
.warehouse_add_modal .pagination_select option {
  font-size: 13px;
}
.warehouse_add_modal .select_con > div {
  display: flex;
  gap: 20px;
}
.warehouse_send_icon {
  margin-left: 15px;
  filter: drop-shadow(1px 1px 8px white);
}
.rowOpacity {
  opacity: 0.6;
  background-color: #dfdfdf;
}
.warehouse_inventory_edit_modal .row {
  display: flex;
  justify-content: center;
}
.warehouse_inventory_edit_modal_description span {
  width: fit-content;
  text-transform: capitalize;
}
.warehouse_inventory_edit_modal_description span b {
  margin-left: 5px;
}
.return_entry_verify_modal .select_con {
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 25px;
}
.return_entry_verify_modal .pagination_select {
  margin-left: 0;
  width: 100%;
  color: #494949;
  padding: 8px 10px 8px 10px;
}
.warehouse_return_modal.modal-header {
  position: relative;
  padding: 1.5rem 1rem;
}
.warehouse_return_modal .heading_input_child {
  position: absolute;
  width: 250px !important;
  top: 18px;
  right: 60px;
  height: 50% !important;
}

/* form  */
.new_form {
  display: grid;
}
.new_form button {
  width: fit-content;
  justify-self: flex-end;
}
.new_form label {
  margin-left: 5px;
}
.new_form input {
  font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
}
.con .shadow {
  border: none;
  border-radius: 15px;
}

/* select  */
.pagination_select {
  outline: none;
  margin-left: 10px;
  display: flex;
  gap: 5px;
  padding: 1px;
  height: fit-content;
  cursor: pointer;
  padding: 5px 10px 5px 10px;
  border: 1px solid #7bebff;
  border-right: 2px solid #11cdef !important;
  border-bottom: 2px solid #11cdef !important;
  border-radius: 5px;
  background: #f9f9f9 !important;
  box-shadow: 0 1px 3px -2px #9098a9;
  font-size: 16px;
  transition: all 150ms ease;
}
.pagination_select:focus {
  outline: none;
  border-color: #11cdef;
  box-shadow: 0 0 0 2px rgba(0, 119, 255, 0.2);
}

/* input  */
input[type="file"] {
  background: transparent;
  height: 100%;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  overflow: hidden;
}
input[type="file"]::file-selector-button {
  background: transparent;
  height: 100%;
  cursor: pointer;
  background: #11cdef;
  color: #ffffff;
  box-shadow: 0rem 0.25rem 0.375rem 0rem rgba(52, 71, 103, 0.1),
    0rem 0.0625rem 0.1875rem 0rem rgba(0, 0, 0, 0.08);
  padding: 0.625rem 1.25rem;
  font-size: 0.875rem;
  font-weight: 700;
  border-radius: 0.5rem;
  border: none;
  font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
}
input[type="date"] {
  text-transform: uppercase;
}
.inportfile_inputFile > * {
  padding: 4px !important;
}
.import_button {
  width: fit-content;
}

/* Profile */
.Profile_Name {
  text-transform: capitalize !important;
}

/* image  */
.seller_product_image {
  cursor: pointer;
}
.seller_product_image .small_image {
  max-width: 48px;
  border-radius: 50%;
  border: 1px solid #e5e5e5;
}
.image_modal {
  width: 500px !important;
}
.image_modal .big_image {
  width: 100%;
  border-radius: 8px;
}
.image_modal .modal-body {
  aspect-ratio: 1/1;
}
.image_modal .modal-content {
  border: none !important;
}
.product_seller_image_id {
  text-align: center;
}

/* checkbox */
.rowSelected {
  background-color: #add8e65e;
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

/* dropdown */
.order_dropdown_menu ul {
  display: flex !important;
  flex-direction: column !important;
  gap: 10px !important;
}
.order_dropdown_menu ul li:nth-child(1) {
  color: #fb6340;
}
.order_dropdown_menu ul li:nth-child(2) {
  color: green;
}
.order_dropdown_menu ul li:nth-child(3) {
  color: red;
}
.order_dropdown_menu ul li {
  gap: 10px;
}
.order_dropdown_menu ul li svg {
  font-size: 1.2rem !important;
}
.order_dropdown_menu.adminOrder ul li {
  color: #000;
  gap: 15px;
}
.admin_order_filter_button button {
  border: 1px solid #d6d6d6;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 8px 15px;
}
.admin_order_filter_button button span {
  font-size: 13px;
  color: #000;
}
.admin_order_filter_button button span svg {
  font-size: 22px;
  margin-left: 5px;
}

/* badge */
.order_badge span {
  width: 80px;
}

/* select  */
.new_modal .select_con {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 12px;
}
.new_modal .select_con span {
  margin-bottom: 5px;
  margin-left: 6px;
}
.new_modal .select_con select {
  width: 100%;
  margin-left: 0;
  height: 2.5rem;
  background: #fff !important;
  font-size: 0.8rem;
  font-weight: 400;
  border-radius: 0.5rem;
  color: #495057;
}
.product_category_badge span {
  min-width: 80px;
  font-size: 11px !important;
  font-weight: 400 !important;
}

/* input  */
.seller_address {
  width: 100% !important;
}
.seller_address textarea {
  min-height: 50px !important;
  resize: vertical !important;
}
.seller_file > div > div {
  padding: 0 !important;
}
