.signin_con_upper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.signin_con_upper .signin_con {
  max-width: 430px;
  min-width: 350px;
  height: fit-content;
  padding: 15px;
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}
.signin_email_input > div,
.signin_password_input > div {
  border-color: #f3f3f3;
  background-color: #f9f9f9 !important;
}
.signin_password_input {
  position: relative;
}
.signin_password_input .signin_password_toggle {
  position: absolute !important;
  top: 2px;
  right: 2px;
  padding: 11.2px !important;
}
.signin_password_input .signin_password_toggle:hover {
  transform: translateX(0);
}
.signin_password_input .signin_password_toggle svg {
  font-size: 1.4rem !important;
}
.auth_bg {
  background: linear-gradient(1deg, #1a6edb, #47e2ff) !important;
}
.signin_animation {
  filter: drop-shadow(16px 16px 20px #11cdef);
}
.signin_con {
  max-width: 430px;
}
