.Toastify__toast-container {
  width: fit-content !important;
}
.Toastify__toast {
  align-items: center;
  overflow: visible !important;
}
.Toastify__toast-body {
  max-width: 80vh;
}
.Toastify__toast-body > div:last-child {
  width: max-content;
}
.Toastify__close-button {
  align-self: center !important;
}
.Toastify__toast-container {
  z-index: 9999999999 !important;
}
