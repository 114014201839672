.sidebar > * {
  height: 100% !important;
  border-radius: 0 1rem 1rem 0 !important;
  margin: 0 !important;
  z-index: 100 !important;
  box-shadow: none !important;
  overflow-x: hidden;
}
.sidebar div::-webkit-scrollbar {
  display: none;
}
.sidebar div {
  scrollbar-width: none;
}
.sidebar div {
  -ms-overflow-style: none;
}
.sidebar .sidebar_logo {
  width: 120px !important;
}
.sidebar_menu_con ul {
  padding-inline: 8px;
}
.sidebar_menu_con ul .sidebar_menu,
.sidebar_menu_con ul .sidebar_menu li {
  margin-block: 5px;
}
.sidebar_menu_con ul .sidebar_menu a {
  border-radius: 8px;
  padding-left: 14px;
  padding-right: 14px;
}
.sidebar_close .sidebar_menu_con ul {
  padding-right: 155px;
  transition: all 0.2s ease;
}
.sidebar_close .sidebar_logo {
  opacity: 0;
  transition: all 0.2s ease;
}
.sidebar_menu .ps-submenu-content.ps-open {
  overflow: hidden !important;
  transition: all 0.3s ease;
}
.sidebar_menu_con .ps-menu-button:hover {
  background: linear-gradient(
    21deg,
    rgb(219, 235, 245) 0%,
    rgba(29, 243, 253, 0.5) 50%,
    rgba(69, 124, 252, 0.5) 100%
  );
}

.warehouse_return_icon {
  transform: rotateY(180deg);
}
